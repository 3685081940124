@import 'sass';

.Wrapper {
	@include flex-center-center;
	height: 100vh;
	padding-top: map-get($sizes, sizes_nav_dt_tall);

	iframe {
		height: 100% !important;
	}
}
