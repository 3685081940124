@import 'sass';

.FilterWrapper {
	@include flex-center-center;
	@include no-select;
	$color-primary: map-get($colors, color_primary);
	padding: 20px 0;
	flex-wrap: wrap;

	.FilterOption {
		@include hover-cursor;
		margin: 2px 3px;
		padding: 6px 18px;
		border: 1px solid $color-primary;
		border-radius: map-get($borders, borders_small_radius);
		transition: 0.14s;
		white-space: nowrap;

		&[data-active='0'] {
			p {
				color: $color-primary;
			}
		}

		&[data-active='1'] {
			border-color: $color-primary;
			background-color: $color-primary;
			p {
				color: white;
			}
		}
	}
}
