@import 'sass';

.SectionLearnLawn {
	@include flex-center-center;
	// @include center-cover-bg;

	position: relative;
	min-height: 80vh;
	background-position-x: center !important;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;

	&[data-picture='1'] {
		min-height: 100vh;
		.Overlay {
			@include bg-overlay-2;
			z-index: 1;
		}
		.Title,
		.Sub {
			color: white;
			text-align: center;
		}
	}

	.Title,
	.Sub {
		color: map-get($colors, color_primaryBlack);
		text-align: center;
	}

	.Content {
		@include reset-text-margins;
		@include flex-col-center-center;

		padding: 40px 0;
		position: inherit;
		z-index: 10;

		.Title {
			@include banner-txt-default;
		}

		.Sub {
			@include sub-default;
			padding: 10px 30px;
		}

		.TilesWrapper {
			@include flex-center-center;
			flex-wrap: wrap;
			margin-bottom: 10px;
			width: 100%;
		}
	}

	@media #{map-get($media, mobile)} {
		background-attachment: unset;
		background-size: cover !important;
		background-position: center !important;
		height: unset !important;
	}
}
