@import 'sass';

.Wrapper {
	@include center-cover-bg;
	border-radius: 20px;
	position: relative;
	margin: 10px;
	overflow: hidden;

	.Overlay {
		@include bg-overlay-2;
		border-radius: inherit;
		z-index: 1;
	}

	&:hover {
		@include hover-cursor;

		.Inner {
			margin-top: 0 !important;
		}
	}

	.Inner {
		@include flex-col-start;
		box-sizing: border-box;
		position: inherit;
		z-index: 2;
		height: 100%;
		transition: 0.23s;
		margin-top: 100% !important;

		.Summary {
			font-weight: 300;
		}

		.Title {
			position: absolute;
			top: -100% !important;
			left: 00px;
			padding: 10px 20px;
		}

		.TitleInner {
			margin: 0;
		}

		.Title,
		.Summary,
		.TitleInner {
			color: white;
		}

		.LinkWrapper {
			@include flex-center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			color: white;

			p {
				font-size: 1.3rem;
				margin: 0;
			}

			svg {
				margin-left: 10px;
				font-size: 1.2rem;
			}
		}
	}

	@media #{map-get($media, mobile_sm)} {
		width: 300px;
		height: unset;

		.Inner {
			margin-top: 0 !important;
			padding: 30px 25px;

			.Summary {
				@include p-summary;
				padding: 10px 0 40px 0;
			}

			.Title {
				font-size: 3rem;
				margin-bottom: 10px !important;
			}
		}
	}

	@media #{map-get($media, mobile_lg)} {
		width: 300px;
		height: 300px;

		.Inner {
			padding: 30px 25px;

			.Summary {
				font-size: 2rem;
				line-height: 2.1rem;
			}

			.Title {
				font-size: 3rem;
				margin-bottom: 40px !important;
			}
		}
	}

	@media #{map-get($media, tablet)} {
		width: 300px;
		height: 300px;

		.Inner {
			padding: 20px;

			.Summary {
				font-size: 1.6rem;
				line-height: 1.8rem;
			}

			.Title {
				font-size: 2.5rem;
				margin-bottom: 10px !important;
			}
		}
	}

	@media #{map-get($media, cpu_min)} {
		width: 300px;
		height: 300px;

		.Inner {
			padding: 20px;

			.Summary {
				font-size: 1.8rem;
				line-height: 2rem;
			}

			.Title {
				font-size: 2.7rem;
				margin-bottom: 8px !important;
			}
		}
	}
}

.CardGroup {
	@include flex-center-center;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 800px;
}
