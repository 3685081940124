.Graphic {
	// This 2px offset is a hot fix to a strange issue with the asset.
	width: calc(100% + 2px);
	left: -1px;
	position: absolute;
	z-index: 1;

	&[data-pos='top'] {
		top: -1px;
	}

	&[data-pos='bottom'] {
		bottom: -1px;
	}
}
