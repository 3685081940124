@import 'sass';

.LinkContainer {
	@include flex-col-center;
	position: relative;
	z-index: 121;
	padding: 85px 30px;
	overflow: hidden;
}

.SectionLabel {
	@include p-summary;
	margin: 15px 0 15px 0 !important;
	font-weight: 700 !important;
}

.Link {
	@include sub-default;

	padding: 8px 0;
	margin: 0 !important;
}

.Link,
.SectionLabel {
	color: white;

	&:hover {
		color: map-get($colors, color_secondary);
	}
}
.SubLinkWrapper {
	@include flex-col-start;
	padding: 0 0 20px 12px;

	.Link {
		font-size: 2.5vw !important;
		line-height: 2.7vw !important;
	}

	@media #{map-get($media, mobile_sm)} {
		.Link {
			font-size: 5.5vw !important;
			line-height: 5.7vw !important;
		}
	}

	@media #{map-get($media, mobile_lg)} {
		.Link {
			font-size: 3.5vw !important;
			line-height: 3.7vw !important;
		}
	}

	@media #{map-get($media, tablet)} {
		.Link {
			font-size: 2.8vw !important;
			line-height: 3vw !important;
		}
	}
}
