@import './sass/';

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
	font-family: 'Lato', sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
	overflow: hidden;
}

.App {
	//----------------------------------------------------------
	// DEV NOTE :: REQUIRED FOR SCROLL TO TOP FUNCTION TO WORK
	//----------------------------------------------------------
	@include remove-scrollbar;
	height: 100vh;
	width: 100vw;
	overflow-y: scroll;
	overflow-x: hidden;
	//----------------------------------------------------------
}
