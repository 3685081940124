@import 'sass';

.Container {
	position: absolute;
	z-index: 122;
	top: 0;
	left: 0;
	width: 0;
}

.ContactWrapper {
	@include flex-center;
	position: absolute;
	z-index: 121;
	right: 5px;
	top: 12px;
	opacity: 1;
	transition: 0.2s;
	transition-timing-function: ease-in-out;

	&[data-toggle-open='1'],
	&[data-contact-open='1'] {
		opacity: 0;
	}
}

.Container,
.ContactWrapper {
	@media #{map-get($media, cpu_min)} {
		display: none !important;
	}
}
