@import 'sass';

.SectionWrapper {
	position: relative;
	padding: 130px 0 250px 0;

	.Graphic {
		position: absolute;
		width: 20%;
		top: 20px;
		left: 10px;
		z-index: 2;
		opacity: 0.15;
	}

	@media #{map-get($media, mobile)} {
		padding: 40px 0 160px 0;

		.Graphic {
			display: none;
		}
	}

	.Title,
	.Sub {
		text-align: center;
	}

	.Title {
		@include body-hdr-default;
		margin: 0;
	}

	.Sub {
		@include sub-default;
		color: map-get($colors, color_secondary);
		margin: 0;
	}

	.Body {
		p {
			@include p-default;
			&:last-of-type {
				margin-bottom: 80px;

				@media #{map-get($media, mobile)} {
					margin-bottom: 40px;
				}
			}
		}
	}

	.Btn {
		z-index: 150;
		position: relative;
	}
}
