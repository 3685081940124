@import 'sass';

.SectionWrapper {
	position: relative;
	width: 100vw;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;

	.TopGraphic {
		width: 100%;
		left: 0;
		top: -1px;
		position: absolute;
		z-index: 1;
	}

	.SectionInner {
		position: inherit;
		z-index: 2;
		padding: 130px 0 200px 0;

		@media #{map-get($media, mobile_lg)} {
			padding: 14vw 0 200px 0;
		}

		@media #{map-get($media, cpu_sm)} {
			padding: 9vw 0 200px 0;
		}

		@media #{map-get($media, cpu_m)} {
			padding: 12vw 0 200px 0;
		}

		@media #{map-get($media, widescreen)} {
			padding: 200px 0;
		}

		.SectionDetails {
			max-width: 900px;
			margin: 0 auto;

			.Title {
				position: relative;
			}

			.Title h1 {
				@include body-hdr-default;
				margin: 0;
			}

			.Title h1:first-of-type {
				color: map-get($colors, color_primaryBlack);
			}

			.Title h1:last-of-type {
				color: map-get($colors, color_secondary);
			}

			.Description {
				@include p-summary;
				padding: 10px 10px 25px 10px;
			}

			.ButtonsWrapper {
				@include flex-center;

				@media #{map-get($media, mobile)} {
					display: none !important;
				}
			}

			// Mascot Service Graphic
			.ServiceGraphic {
				width: 80%;
				opacity: 0.35;
			}
		}

		.TilesWrapper {
			@include flex-center-center;
			flex-wrap: wrap;
			margin: 0 auto;
			max-width: 800px;
		}

		.ServiceGraphicMobile {
			position: absolute;
			width: 220px;
			transform: scaleX(-1);
			bottom: 0;
			right: 0;

			@media #{map-get($media, mobile_sm)} {
				width: 170px;
				transform: scaleX(1);
				right: -40px;
			}

			@media #{map-get($media, tablet)} {
				position: absolute;
				width: 300px;
			}

			@media #{map-get($media, cpu_min)} {
				display: none;
			}
		}
	}
}
