@import 'sass';

.SectionWrapper {
	position: relative;
	min-height: 500px;

	.Content {
		position: inherit;
		z-index: 10;
		padding: 20px 10px 300px 10px;

		.Crumbs {
			margin-bottom: 25px;
		}

		.Body {
			p {
				@include p-default;

				&:last-of-type {
					margin-bottom: 70px;
				}
			}
		}
	}

	.MascotContainer {
		position: inherit;
		.Mascot {
			position: absolute;
			z-index: 0;

			@media #{map-get($media, mobile)} {
				bottom: 0px;
				right: 10px;
				width: 280px;
			}

			@media #{map-get($media, tablet)} {
				bottom: 10px;
				right: 80px;
				width: 280px;
			}

			@media #{map-get($media, cpu_sm)} {
				bottom: 40px;
				right: 160px;
				width: 230px;
			}

			@media #{map-get($media, cpu_m)} {
				bottom: 45px;
				right: 100px;
				width: 240px;
			}

			@media #{map-get($media, widescreen)} {
				bottom: 60px;
				right: 100px;
				width: 250px;
			}
		}
	}
}
