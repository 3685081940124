@import 'sass';

.Wrapper {
	width: 100%;
	border: 2px solid map-get($colors, color_primary);
	border-radius: map-get($borders, borders_small_radius);
	margin: 10px auto;

	.Header {
		padding: 12px 5px;
		background-color: map-get($colors, color_primary);
		border-top-left-radius: calc(#{map-get($borders, borders_small_radius) - 5px});
		border-top-right-radius: calc(#{map-get($borders, borders_small_radius) - 5px});

		h3 {
			text-align: center;
			color: white;
		}
	}

	.Content {
		padding: 20px 30px;

		&[data-flow='col'] {
			@include flex-col-start;
		}

		&[data-flow='row'] {
			@include flex-start;
		}
	}

	@media #{map-get($media, mobile)} {
		margin: 30px auto 0 auto;
	}
}
