@import 'sass';

.SectionOuter {
	position: relative;
	min-height: 500px;

	.SectionInner {
		padding: 35px 0 270px 0;
		position: relative;
		z-index: 100;

		.Body {
			.ServicesProvidedContainer {
				p {
					@include p-default;
					margin: 0 !important;
				}

				.TagContainer {
					@include flex-start;

					.ServiceTag {
						padding: 3px 8px;
						margin: 4px 3px;
						background-color: map-get($colors, color_secondary);
						border-radius: map-get($borders, borders_small_radius);

						p {
							@include p-small;
							color: white;
						}
					}
				}
			}

			h1 {
				@include body-hdr-default;
			}

			h3 {
				@include sub-default;
				color: map-get($colors, color_secondary);
			}

			p {
				@include p-default;
			}

			button {
				margin-top: 40px !important;
			}
		}
	}
}
