@import 'sass';

.Wrapper {
	@include flex-center-center;

	&[data-color='primary'] {
		svg {
			color: map-get($colors, color_primary);
		}
	}
	&[data-color='secondary'] {
		svg {
			color: map-get($colors, color_secondary);
		}
	}
	&[data-color='black'] {
		svg {
			color: map-get($colors, color_primaryBlack);
		}
	}
	&[data-color='white'] {
		svg {
			color: white;
		}
	}
}
