@import 'sass';

.HeaderWrapper {
	@include center-cover-bg;
	@include flex-center;
	min-height: 600px;
	position: relative;

	.Overlay {
		@include overlay-dark;
	}

	.Inner {
		@include flex-col-center-center;
		position: inherit;
		z-index: 2;
		margin-top: 30px;

		img {
			width: 100%;
			max-width: 850px;
		}

		h3 {
			@include body-hdr-default;
			color: white;
			font-weight: 700 !important;
			letter-spacing: 2px !important;
			text-align: center;
		}

		.ButtonWrapper {
			@include flex-center-center;
			width: 100%;
		}
	}
}
