@import 'sass';

.ReviewWrapper {
	@include flex-center;

	.Left {
		@include flex-col-center-center;

		img {
			width: 100px;
			border-radius: 100%;
		}

		.RatingWrapper {
			@include flex-center-center;
			width: 100px;
			padding: 10px;

			svg {
				color: map-get($colors, color_ratingYellow);
			}
		}
	}

	.Right {
		position: relative;
		color: white;
		padding: 10px 15px;

		p {
			margin: 0;

			&:first-of-type {
				@include p-default;
				font-weight: 500 !important;
			}

			&:last-child {
				@include p-summary;
				text-align: right;
				margin-right: 20%;
			}
		}
	}

	@media #{map-get($media, mobile)} {
		@include flex-col-center-center;

		.Left {
			width: 100%;
			align-items: flex-start !important;
			padding-left: 15px;
		}
	}
}
